<template>
  <div class="bg_view" v-if="userInfo">
    <!-- 导航栏 -->
    <div class="header_view">
      <van-popover :actions="loginActions" v-model="showLoginPopMenu" trigger="click" placement="bottom-start" @select="loginMenuAction">
        <template #reference>
          <van-button class="user_btn" type="info" round>{{ userInfo.userName ? userInfo.userName.substring(userInfo.userName.length - 1) : "" }}</van-button>
        </template>
      </van-popover>
      <div class="name_tx">{{ userInfo.userName }}</div>
      <!-- 项目总选择下属 -->
      <div class="menu_view" v-if="userInfo.isManager == 1" @click="showMyJuniorPicker = true">
        <span>{{ screenUser.name ? `已选 | ${screenUser.name}` : "所有下级" }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="showMyJuniorPicker" round position="bottom">
      <van-picker show-toolbar :columns="userInfo.myJuniorList" value-key="name" cancel-button-text="重置" @cancel="resetChooseAction" @confirm="chooseMyJuniorAction" />
    </van-popup>
    <!-- 我的业绩模块 -->
    <div class="my_info_view">
      <div class="title_tx">我的业绩</div>
      <!-- 规则按钮 -->
      <van-icon name="info-o" class="rule_btn" @click="ruleClick" v-show="showRuleBtn" />
      <div class="content_view">
        <div class="item_view">
          <div>累计预估销售额</div>
          <div>{{ juniorInfo.myPerformanceInfo ? juniorInfo.myPerformanceInfo.totalForecastSale : "-" }}</div>
        </div>
        <div class="item_view" v-if="userInfo.isManager == 1">
          <div>累计预估佣金</div>
          <div style="color: #FF7D00;">{{ juniorInfo.myPerformanceInfo ? juniorInfo.myPerformanceInfo.totalForecastBrokerage : "-" }}</div>
        </div>
        <div class="item_view" v-else>
          <div>累计有效销售额</div>
          <div style="color: #FF7D00;">{{ juniorInfo.myPerformanceInfo ? juniorInfo.myPerformanceInfo.totalValidSale : "-" }}</div>
        </div>
      </div>
    </div>
    <!-- 筛选栏 -->
    <div class="screen_menu_view">
      <template v-for="(item, index) in ['今日', '昨日', '当月', '上月', '']">
        <div class="item_view" :key="`key_${index}`" :class="`${index == screenTimeType ? 'is_active' : ''}`" @click="screenClick(index)">
          <span>{{ index == 4 ? getScreenChooseTimeText : item }}</span>
        </div>
      </template>
      <div class="empty_view"></div>
    </div>
    <div class="hide_scroll_bar"></div>
    <!-- 日期选择 -->
    <van-calendar :allow-same-day="true" :default-date="calendarDefaultTime" :lazy-render="true" :min-date="calendarMinTime" v-model="showScreenCalendar" type="range" @confirm="screenCalendarChooseAction" />
    <!-- 全部订单按钮 -->
    <div class="order_btn">
      <span @click="gotoOrderAction" :class="`${userInfo.isManager != 1 ? 'big_order_btn' : ''}`">
        <span>全部订单明细</span>
        <van-icon name="arrow" />
      </span>
      <div v-if="userInfo.isManager == 1" class="download_btn" @click.stop="() => { isShowDownloadCalender = true, showScreenCalendar = true }">导出</div>
    </div>
    <!-- 业绩列表数据 -->
    <div class="list_view" v-if="juniorInfo.screenPerformanceInfo && juniorInfo.todayInfo">
      <div class="list_item">
        <div class="item_tx_view">
          <div class="tx_1">预估销售额</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.forecastSale }}</div>
        </div>
      </div>
      <div class="list_item" v-if="juniorInfo.screenPerformanceInfo.isManager == 1">
        <div class="item_tx_view">
          <div class="tx_1">预估佣金</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.forecastBrokerage }}</div>
        </div>
      </div>
      <div class="list_item">
        <div class="item_tx_view">
          <div class="tx_1">有效销售额</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.validSale }}</div>
        </div>
      </div>
      <div class="list_item" v-if="juniorInfo.screenPerformanceInfo.isManager == 1">
        <div class="item_tx_view">
          <div class="tx_1">有效佣金</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.validBrokerage }}</div>
        </div>
      </div>
      <div class="list_item">
        <div class="item_tx_view">
          <div class="tx_1">预估订单数</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.forecastOrderNum }}</div>
        </div>
      </div>
      <div class="list_item">
        <div class="item_tx_view">
          <div class="tx_1">有效订单数</div>
          <div class="tx_2">{{ juniorInfo.screenPerformanceInfo.validOrderNum }}</div>
        </div>
      </div>
      <div class="list_item" style="width: 100%;justify-content: space-around;">
        <div class="item_tx_view" style="margin: 0;">
          <div class="tx_1">今日付款订单数</div>
          <div class="tx_2">{{ juniorInfo.todayInfo.orderNum }}</div>
        </div>
        <div class="item_tx_view" style="margin: 0;">
          <div class="tx_1">今日付款销售额</div>
          <div class="tx_2">{{ juniorInfo.todayInfo.sale }}</div>
        </div>
        <div class="item_tx_view" style="margin: 0;">
          <div class="tx_1">今日预估佣金</div>
          <div class="tx_2">{{ juniorInfo.todayInfo.brokerage }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/common/axios';
import { Toast } from 'vant';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh');
export default {
  data() {
    return {
      showRuleBtn: false,
      showLoginPopMenu: false,
      loginActions: [{ text: "退出登录" }, { text: "取消" }],
      showMyJuniorPicker: false,
      screenUser: {},
      userInfo: null,
      juniorInfo: {},
      // 0 今日 1昨日 2近7日 3近30日 4自定义时间
      screenTimeType: 0,
      screenChooseTime: {
        start: "",
        end: "",
      },
      calendarMinTime: new Date(moment().subtract(2, 'years').add(1, 'days').valueOf()),
      calendarDefaultTime: [new Date(), new Date()],
      showScreenCalendar: false,
      // 导出时间选择
      isShowDownloadCalender: false
    };
  },
  computed: {
    getScreenChooseTimeText() {
      if (!this.screenChooseTime.start) {
        return "自定义";
      } else if (this.screenChooseTime.start == this.screenChooseTime.end) {
        return this.screenChooseTime.start;
      } else {
        return `${this.screenChooseTime.start}至${this.screenChooseTime.end}`;
      }
    }
  },
  mounted() {
    // localStorage.setItem("propertyManagerAccountInfo", JSON.stringify({token: "23f5cede8ea941a883b57778beeacb7d"}))
    document.title = "首页";
    let accountInfo = localStorage.getItem('propertyManagerAccountInfo');
    if (!accountInfo) {
      this.$router.replace('/propertyManager/login');
      return;
    }
    this.getUserInfo();
    this.getRuleData();
  },
  methods: {
    loginMenuAction(action, index) {
      if (index == 0) {
        localStorage.removeItem('propertyManagerAccountInfo');
        this.$router.replace('/propertyManager/login');
      }
    },
    chooseMyJuniorAction(e) {
      this.showMyJuniorPicker = false;
      if (this.screenUser.name == e.name || this.screenUser.phone == e.phone) {
        return;
      }
      this.screenUser = { ...e };
      this.screenTimeType = 0;
      setTimeout(() => {
        let dom = this.$el.getElementsByClassName('screen_menu_view')[0];
        dom.scrollLeft = 0;
      });
      this.screenChooseTime = { ...this.screenChooseTime, start: "", end: "" };
      this.getData();
    },
    resetChooseAction() {
      this.showMyJuniorPicker = false;
      if (!this.screenUser.name) {
        return;
      }
      this.screenUser = {};
      this.getData();
    },
    ruleClick() {
      this.$router.push('/propertyManager/rule');
    },
    // 筛选条件点击
    screenClick(index) {
      if (index == 4) {
        this.isShowDownloadCalender = false;
        this.showScreenCalendar = true;
      } else {
        if (this.screenTimeType == index) {
          return;
        }
        this.screenTimeType = index;
        this.screenChooseTime = { ...this.screenChooseTime, start: "", end: "" };
        this.getData();
      }
    },
    screenCalendarChooseAction(e) {
      if (this.isShowDownloadCalender) {
        this.downloadAction(e);
        return;
      }
      let [startTime, endTime] = e;
      let now = moment();
      let start = moment(startTime);
      let end = moment(endTime);
      if (start.isAfter(now) || end.isAfter(now)) {
        Toast("所选时间区间不可大于今天");
        return;
      }
      this.screenChooseTime.start = start.format('YYYY-MM-DD');
      this.screenChooseTime.end = end.format('YYYY-MM-DD');
      this.showScreenCalendar = false;
      this.screenTimeType = 4;
      setTimeout(() => {
        let dom = this.$el.getElementsByClassName('screen_menu_view')[0];
        dom.scrollLeft = dom.scrollWidth - dom.clientWidth;
      });
      this.getData();
    },
    // 获取用户信息
    getUserInfo() {
      API({
        url: "/h5/property/getUserInfo.do",
        data: {}
      }).then(res => {
        if (res.success == 1) {
          this.userInfo = res.data;
          this.getData();
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        Toast("服务异常，请刷新页面重试！");
      });
    },
    // 获取业绩信息
    getData() {
      let params = { screenPhone: this.screenUser.phone || "" };
      if (this.screenTimeType == 0) {
        // 今日
        params.screenStartTime = moment().format('YYYY-MM-DD');
        params.screenEndTime = params.screenStartTime;
      } else if (this.screenTimeType == 1) {
        // 昨日
        params.screenStartTime = moment().subtract(1, 'day').format('YYYY-MM-DD');
        params.screenEndTime = params.screenStartTime;
      } else if (this.screenTimeType == 2) {
        // 当月
        params.screenStartTime = moment().startOf('month').format('YYYY-MM-DD');
        params.screenEndTime = moment().format('YYYY-MM-DD');
      } else if (this.screenTimeType == 3) {
        // 上月
        params.screenStartTime = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        params.screenEndTime = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      } else {
        params.screenStartTime = this.screenChooseTime.start;
        params.screenEndTime = this.screenChooseTime.end;
      }
      const loading = Toast.loading({
        loadingType: "spinner",
        position: "middle",
        message: "加载中..."
      });
      if (this.juniorInfo.myPerformanceInfo) {
        this.juniorInfo = { myPerformanceInfo: this.juniorInfo.myPerformanceInfo };
      } else {
        this.juniorInfo = {};
      }
      API({
        url: "/h5/property/getPerformanceInfo.do",
        data: params
      }).then(res => {
        loading.clear();
        if (res.success == 1) {
          this.juniorInfo = res.data;
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        loading.clear();
        Toast("服务异常，请刷新页面重试！");
      });

    },
    getRuleData() {
      API({
        url: "/h5/property/getPerformanceIntro.do",
        data: {}
      }).then(res => {
        if (res.success == 1 && res.data && res.data.ruleId) {
          this.showRuleBtn = true;
        }
      });
    },
    downloadAction(e) {
      let [startTime, endTime] = e;
      let now = moment();
      let start = moment(startTime);
      let end = moment(endTime);
      if (start.isAfter(now) || end.isAfter(now)) {
        Toast("所选时间区间不可大于今天");
        return;
      }
      this.showScreenCalendar = false;
      this.isShowDownloadCalender = false;
      let accountInfo = JSON.parse(localStorage.getItem('propertyManagerAccountInfo') || "{}");
      let path = `${API.defaults.baseURL}/h5/property/exportPerformanceInfo.do?screenStartTime=${start.format('YYYY-MM-DD')}&screenEndTime=${end.format('YYYY-MM-DD')}&token=${accountInfo.token}&screenPhone=`;
      window.open(path, '_self');
    },
    gotoOrderAction() {
      this.$router.push('/propertyManager/orderList');
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.bg_view {
  background-color: #F5F5F7;
  min-height: 100%;
  background-image: url('~@/assets/property_bg.png');
  background-size: 100% 302px;
  background-repeat: no-repeat;
}

.header_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 13px 0 21px;

  .name_tx {
    margin-right: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #333333;
    font-size: 18px;
    margin-left: 11px;
  }

  .user_btn {
    width: 50px;
    height: 50px;
    background-color: #784CFA;
    font-size: 21px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #FFFFFF;
  }

  .menu_view {
    margin-right: 0;
    margin-left: auto;
    padding: 0 6px 0 10px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #784CFA;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #784CFA;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.my_info_view {
  background-color: #FFFFFF;
  margin: 21px 12px 0 12px;
  height: 146px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;

  .title_tx {
    height: 45px;
    background-color: #FAF8FF;
    line-height: 45px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    font-style: normal;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding-left: 16px;
  }

  .rule_btn {
    position: absolute;
    right: 12px;
    top: 16px;
    width: 15px;
    height: 15px;
    color: #666666;
  }

  .content_view {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 40px;
      top: 30px;
      left: 50%;
      background-color: #F7F8FA;
    }

    .item_view {
      width: 50%;
      text-align: center;
      position: relative;

      div:nth-child(1) {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        font-style: normal;
      }

      div:nth-child(2) {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 23px;
        color: #333333;
        line-height: 34px;
        font-style: normal;
        margin-top: 6px;
      }
    }
  }
}

.screen_menu_view {
  display: flex;
  margin-top: 16px;
  overflow-x: auto;
  white-space: nowrap;
  height: 41px;
  position: relative;
  padding-right: 12px;

  .item_view {
    padding: 0 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 25px;
    margin-left: 11px;
    height: 25px;
    border: 1px solid #999999;
    border-radius: 13px;
    box-sizing: border-box;
  }

  .is_active {
    border: 1px solid #784CFA;
    background-color: rgba(120, 76, 250, 0.16);
    color: #784CFA;
  }

  .empty_view {
    width: 12px;
    height: 100%;
    flex-shrink: 0;
  }
}

/deep/ {
  .van-calendar__footer {
    padding-bottom: 20px;
  }

  .van-calendar__day--middle {
    color: #784CFA;
  }

  .van-calendar__day--end,
  .van-calendar__day--multiple-middle,
  .van-calendar__day--multiple-selected,
  .van-calendar__day--start,
  .van-calendar__day--start-end {
    background-color: #784CFA;
  }

  .van-button--danger {
    background-color: #784CFA;
    border-color: #784CFA;
  }
}

.hide_scroll_bar {
  position: absolute;
  width: 100%;
  height: 8px;
  z-index: 2;
  background-color: #F5F5F7;
  margin-top: -8px;
}

.order_btn {
  font-size: 14px;
  background-color: #ccc;
  display: flex;
  padding: 0 12px 11px 16px;
  margin: 0 12px;
  height: 49px;
  border-radius: 10px 10px 0 0;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  font-style: normal;
  background-color: #FAF8FF;

  .big_order_btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .van-icon {
    margin-left: 6px;
  }

  .download_btn {
    margin-right: 0;
    margin-left: auto;
    width: 60px;
    height: 25px;
    background: linear-gradient(270deg, #B083FD 0%, #784CFA 100%);
    border-radius: 5px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 25px;
    font-style: normal;
    text-align: center;
  }

}

.list_view {
  background-color: white;
  margin: -11px 12px 0 12px;
  border-radius: 10px;
  padding: 7px 12px 30px 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .list_item {
    display: flex;
    align-items: center;
    width: calc((100% - 9px) / 2);
    background-color: #F7F8FA;
    border-radius: 10px;
    height: 78px;
    overflow: hidden;
    margin-top: 9px;

    .item_tx_view {
      margin-left: 16px;

      .tx_1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        font-style: normal;
      }

      .tx_2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        margin-top: 4px;
      }
    }
  }
}
</style>